var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "create-sn-page" }, [
    _c("section", {
      staticClass: "border-bottom bg-lighter md-up:sticky-top",
      staticStyle: { top: "70px" }
    }),
    _vm._v(" "),
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
            [
              _c("mega-card", { staticClass: "card-border card-round p-4" }, [
                _c(
                  "div",
                  { staticClass: "card-media" },
                  [
                    _c(
                      "mega-drop-zone",
                      {
                        attrs: { type: ["image/jpeg", "image/png"] },
                        on: { change: _vm.renderFile }
                      },
                      [
                        _vm.preview
                          ? _c("mega-image", {
                              staticClass: "image bg-contain",
                              attrs: {
                                contain: "",
                                ratio: "16x9",
                                src: _vm.preview
                              }
                            })
                          : _c("img", {
                              staticClass: "w-100",
                              attrs: { src: _vm.sn.avatar }
                            })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
            [
              _c("mega-card", { staticClass: "card-border card-round p-4" }, [
                _c(
                  "div",
                  { staticClass: "card-media" },
                  [
                    _c("mega-input", {
                      attrs: { label: _vm.$t("new_sn_name") },
                      model: {
                        value: _vm.sn.name,
                        callback: function($$v) {
                          _vm.$set(_vm.sn, "name", $$v)
                        },
                        expression: "sn.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: _vm.$t("new_sn_name_ru") },
                      model: {
                        value: _vm.sn.name_ru,
                        callback: function($$v) {
                          _vm.$set(_vm.sn, "name_ru", $$v)
                        },
                        expression: "sn.name_ru"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: _vm.$t("new_sn_username") },
                      model: {
                        value: _vm.sn.username,
                        callback: function($$v) {
                          _vm.$set(_vm.sn, "username", $$v)
                        },
                        expression: "sn.username"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-textarea", {
                      attrs: { label: _vm.$t("new_sn_description") },
                      model: {
                        value: _vm.sn.description,
                        callback: function($$v) {
                          _vm.$set(_vm.sn, "description", $$v)
                        },
                        expression: "sn.description"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "mega-button",
                      {
                        staticClass: "btn btn-success w-100",
                        on: { click: _vm.submit }
                      },
                      [_vm._v(_vm._s(_vm.$t("sn_save")))]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }