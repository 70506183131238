<template>
  <main class="create-sn-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    ></section>

    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card class="card-border card-round p-4">
              <div class="card-media">
                <mega-drop-zone
                  @change="renderFile"
                  :type="['image/jpeg', 'image/png']"
                >
                  <mega-image
                    v-if="preview"
                    class="image bg-contain"
                    contain
                    ratio="16x9"
                    :src="preview"
                  />
                  <img v-else class="w-100" :src="sn.avatar" />
                </mega-drop-zone>
              </div>
            </mega-card>
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card class="card-border card-round p-4">
              <div class="card-media">
                <mega-input
                  :label="$t('new_sn_name')"
                  v-model="sn.name"
                ></mega-input>
                <mega-input
                  :label="$t('new_sn_name_ru')"
                  v-model="sn.name_ru"
                ></mega-input>
                <mega-input
                  :label="$t('new_sn_username')"
                  v-model="sn.username"
                ></mega-input>
                <mega-textarea
                  :label="$t('new_sn_description')"
                  v-model="sn.description"
                ></mega-textarea>

                <mega-button class="btn btn-success w-100" @click="submit">{{
                  $t("sn_save")
                }}</mega-button>
              </div>
            </mega-card>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "CreateSnPage",
  data() {
    return {
      sn: {},
      avatar: null,
      preview: null
    };
  },
  mounted() {
    this.$navbar.name = this.$t("sn_create");
  },
  methods: {
    async submit() {
      const formData = new FormData();
      formData.append("name", this.sn.name);
      formData.append("name_ru", this.sn.name_ru);
      formData.append("username", this.sn.username);
      formData.append("description", this.sn.description);
      formData.append("avatar", this.avatar);

      await this.$api.v2base
        .post("/sn/sn", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() => {
          this.$alert.success(this.$t("sn_created"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("sn_create_error"));
        });
    },
    renderFile(file) {
      if (!file) {
        this.$alert.danger(this.$t("err_file"));
        return;
      }

      this.avatar = file;

      const reader = new FileReader();
      reader.readAsDataURL(this.avatar);
      reader.onloadend = () => {
        this.preview = reader.result;
      };
    }
  }
};
</script>
